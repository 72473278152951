export const LATEST_FEED_ITEM_WRAPPER_CHANNEL_FRAGMENT = `
    fragment latestFeedItemWrapperChannelFragment on Channel {
      uid
      feed: _channelFeed(
        skip: 0
        limit: 1
        myUid: "%authUser%"
      ) {
          uid
      }
    }
`;
